import React from 'react'
import '../components/bootstrap.min.css';
import '../components/vendor/tether/tether.min.css';
import '../components/vendor/bootstrap/css/bootstrap.min.css';
import '../components/css/fonts/express-icons.css';
import '../components/css/card.css';

import '../components/vendor/font-awesome/css/font-awesome.min.css';
import '../components/vendor/bootstrap/css/glyphicon.css';
import '../components/vendor/cubeportfolio/css/cubeportfolio.min.css';

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import '../components/vendor/owl-carousel/owl.theme.css';
import '../components/vendor/owl-carousel/owl.carousel.css';
import '../components/vendor/magnific-popup/magnific-popup.css';
import '../components/vendor/lite-tooltip/css/litetooltip.css';
import '../components/vendor/PricingTablesInspiration/css/component.css';
import '../components/vendor/PricingTablesInspiration/css/icons.css';
import '../components/vendor/PricingTablesInspiration/css/demo.css';
//Theme CSS
import '../components/css/main.css';
import '../components/css/main-shortcodes.css';
import '../components/css/header.css';
import '../components/css/form-element.css';
import '../components/css/animation.css';
import '../components/css/bg-stylish.css';
import '../components/css/blog.css';
import '../components/css/streamline-icon.css';
import '../components/css/responsive.css';
import '../components/css/utilities.css';
import '../components/css/portfolio.css';
import '../components/css/font-icons.css';
import '../components/css/skins/default.css';

//Theme Custom CSS
import '../components/css/custom.css';

import Footer from "../components/footer";
import Header from "../components/header";


const IndexPage = () => (
<div class="wrapper">
 <Header/>
  <section class="page-header b-bordered">
            <div class="container">
               <div class="row">
                  <div class="col-sm-12">
                     <h1 class="title mt-5">Terms of Use</h1>
                     
                  </div>
               </div>
            </div>
         </section>

         <div class="page-inner p-none no-border">

            <div class="section-primary">
               <div class="container">

                  <div class="row">
                     <div class="col-md-12">

                        <div class="clearfix">
                           <p class="fs-22 lh-30 e-custom-heading">
                              Enthusiastically orchestrate premier web services whereas turnkey relationships. Competently
                              procra stinate goal-oriented catalysts for change through resource-leveling paradigms.
                           </p>
                        </div>
                     </div>
                  </div>

                  <hr class="invisible" />

                  <div class="row">
                     <div class="col-md-12">
                        <p>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque neque eget diam
                           posuere porta. Quisque ut nulla at nunc <a href="#">vehicula</a> lacinia. Proin adipiscing porta tellus,
                           ut feugiat nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus. Vestibulum ante
                           ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula. Fusce eget
                           metus lorem, ac viverra leo. Nullam convallis, arcu vel pellentesque sodales, nisi est varius diam, ac
                           ultrices sem ante quis sem. Proin ultricies.
                        </p>
                        <p>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque neque eget diam posuere
                           porta. Quisque ut nulla at nunc <a href="#">vehicula</a> lacinia. Proin adipiscing porta tellus, ut feugiat
                           nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus. Vestibulum ante ipsum primis in
                           faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula. Fusce eget metus lorem, ac
                           viverra leo. Nullam convallis, arcu vel pellentesque sodales, nisi est varius diam, ac ultrices sem ante
                           quis sem. Proin ultricies volutpat.
                        </p>
                        <p>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque neque eget diam posuere
                           porta. Quisque ut nulla at nunc <a href="#">vehicula</a> lacinia. Proin adipiscing porta tellus, ut feugiat
                           nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus. Vestibulum ante ipsum primis in
                           faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula. Fusce eget metus lorem, ac
                           viverra leo. Nullam convallis, arcu vel pellentesque sodales, nisi est varius diam, ac ultrices sem ante
                           quis sem. Proin ultricies volutpat sapien, nec scelerisque ligula mollis lobortis.
                        </p>
                        <p>
                           Curabitur pellentesque neque eget diam posuere porta. Quisque ut nulla at nunc vehicula lacinia. Proin
                           adipiscing porta tellus, ut feugiat nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id
                           metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero
                           ligula. Fusce eget metus lorem, ac viverra leo.
                        </p>
                        <p>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque neque eget diam posuere
                           porta. Quisque ut nulla at nunc <a href="#">vehicula</a> lacinia. Proin adipiscing porta tellus, ut feugiat
                           nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus. Vestibulum ante ipsum primis in
                           faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula. Fusce eget metus lorem, ac viverra
                           leo. Nullam convallis, arcu vel pellentesque sodales, nisi est varius diam, ac ultrices sem ante quis sem.
                           Proin ultricies volutpat sapien, nec scelerisque ligula mollis lobortis.
                        </p>
                        <p>
                           Curabitur pellentesque neque eget diam posuere porta. Quisque ut nulla at nunc vehicula lacinia. Proin
                           adipiscing porta tellus, ut feugiat nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus.
                           Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula.
                           Fusce eget metus lorem, ac viverra leo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                           posuere cubilia Curae; In eu libero ligula. Fusce eget metus lorem, ac viverra leo. Vestibulum ante
                           ipsum primis in faucibus orci.
                        </p>
                     </div>
                  </div>       
               </div>
            </div>
         </div>
   <Footer/>
</div>
)

export default IndexPage
